import React from "react";

import { Paper, Typography, makeStyles } from "@material-ui/core";

import { FEEDBACK_LINK } from "../constants";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 2),
    padding: theme.spacing(3, 2),
  },
}));

const AccountDeleted = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Typography variant="h4">Account Deleted</Typography>
      <br />
      <Typography variant="body1">
        All of your data has been deleted. Thanks for trying Reconcile! I'm
        sorry it didn't work out for you. If you have any{" "}
        <a href={FEEDBACK_LINK} target="_new">
          feedback
        </a>{" "}
        I'd greatly appreacite it.
      </Typography>
      <br />
      <Typography variant="body1">-Gabe</Typography>
    </Paper>
  );
};

export default AccountDeleted;
